import React from "react";
import { Routes, Route } from "react-router-dom";
import RatingPage from "./RatingPage";
import Thankyou from "./Thankyou";
import AlreadyExist from "./AlreadyExist";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<RatingPage />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/feedbackexist" element={<AlreadyExist />} />
    </Routes>
  );
}
